const dev = {
  backend_url: "http://localhost:3000/api/v1",
};

const staging = {
  backend_url: "http://localhost:3000/api/v1",
};

const production = {
  backend_url: "https://portal.esim.tech/api/v1",
};

export const config = () => {
  return production;
};
