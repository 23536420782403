import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate
} from "react-router-dom";
import Login from "./pages/Login.jsx";
import NotFound from "./pages/NotFound.jsx";
import Home from "./pages/Home.jsx";
import AuthLayout from "./components/auth/AuthLayout.jsx";
import HomeLayout from "./components/auth/HomeLayout.jsx";
import ProtectedRoute from "./components/auth/ProtectedRoute.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import ResetPassword from "./pages/ResetPassword.jsx";
import Bundles from "./pages/Bundles.jsx";
import Checkout from "./pages/Checkout.jsx";

const MainRoutes = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthLayout />}>
        <Route path='*' element={<NotFound />} />
        <Route path="/" element={ <Navigate to="/home" />} />
        <Route element={<ProtectedRoute />}>
          <Route path="home" element={<Home />} />
          <Route path="bundles" element={<Bundles />} />
          <Route path="checkout" element={<Checkout />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Route>
    )  );
};

export default MainRoutes;
