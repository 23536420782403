import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../network/lib/userContext.js";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState("true");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await getUser();
        setUser(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const login = (data) => {
    setUser(data);
    navigate("/home");
  };

  const logout = () => {
    setUser(null);
    navigate("/login");
  };

  const value = {
    user,
    login: login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
