import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../core/context/authContext.jsx";

const ProtectedRoute = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
