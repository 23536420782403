import React, { useEffect, useState } from "react";
import { getAvailableBundles } from "../core/network/lib/orderContext.js";
import { useLocation, useNavigate } from "react-router-dom";
import CountryDropdown from "../components/bundles/CountryDropdown.js";
import LoadingSpinner from "../components/LoadingSpinner.jsx";

const Bundles = () => {
  const [bundles, setBundles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  const { state } = useLocation();
  const { order } = state;
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const result = await getAvailableBundles("");
        setBundles(result?.data?.bundles);
        setCountries(result?.data?.countries);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    })();
  }, []);

  const handleUpgradeClick = async (bundle) => {
    navigate("/checkout", { state: { bundle, order } });
  };

  const handleFilterByCountry = async (country) => {
    try {
      const result = await getAvailableBundles(country);
      setBundles(result?.data?.bundles);
      setCountries(result?.data?.countries);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div class="bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl sm:text-center pb-5">
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Upgrade your bundle
              </h2>
              <p class="mt-6 text-lg leading-8 text-gray-600">
                {" "}
                For esim:
                <br />
                {localDate(order?.created_at)} <br /> {order.iccid}
              </p>
            </div>
            <CountryDropdown
              countries={countries}
              handleFilterByCountry={handleFilterByCountry}
            />

            {bundles?.map((bundle) => {
              return (
                <div class="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                  <div class="p-8 sm:p-10 lg:flex-auto">
                    <h3 class="text-2xl font-bold tracking-tight text-gray-900">
                      {bundle.country}
                    </h3>
                    <p class="mt-6 text-base leading-7 text-gray-600">
                      {bundle.description}
                    </p>
                  </div>
                  <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                      <div class="mx-auto max-w-xs px-8">
                        <p class="mt-6 flex items-baseline justify-center gap-x-2">
                          <span class="text-5xl font-bold tracking-tight text-gray-900">
                            € {parseFloat(bundle.price).toFixed(2)}
                          </span>
                        </p>
                        <button
                          // onClick={() => handleUpgradeClick(bundle)}
                          class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled opacity-20"
                          disabled
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const localDate = (time) => {
  const date = new Date(time);
  const localTime = date.toLocaleString();
  return localTime;
};

export default Bundles;
