import React from "react";

const NotFound = () => {
  return (
    <html class="h-full bg-white">
      <body class="h-full">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/GoSimLogo.webp"
              alt="GoSim"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              404 Page not found
            </h2>
          </div>
        </div>
      </body>
    </html>
  );
};

export default NotFound;
