import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { getStripeClientSecret } from "../core/network/lib/orderContext.js";
import CheckoutForm from "../components/checkout/CheckoutForm.jsx";
import stripe_public_key from "../../../enviroment.js";

const stripePromise = loadStripe(stripe_public_key);

const Checkout = () => {
  const [options, setOptions] = useState("");

  const { state } = useLocation();
  const { bundle, order } = state;

  useEffect(() => {
    (async () => {
      const result = await getStripeClientSecret(bundle?.id, order?.iccid);
      setOptions({ clientSecret: result?.data?.client_secret });
    })();
  }, []);

  if (options) {
    return (
      <Elements stripe={stripePromise} options={options}>
        <div className="containter mx-auto mt-4 mx-2">
          <div class="mx-auto container rounded-3xl lg:flex">
            <div class="pt-8 sm:pt-10 lg:flex-auto">
              <h3 class="text-2xl font-bold tracking-tight text-gray-900">
                {bundle.country}
              </h3>
              <p class="mt-6 text-base leading-7 text-gray-600">
                {bundle.description}
              </p>
            </div>
            <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div class="mx-auto max-w-xs px-8">
                  <p class="flex items-baseline justify-center gap-x-2">
                    <span class="text-5xl font-bold tracking-tight text-gray-900">
                      € {parseFloat(bundle.price).toFixed(2)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <CheckoutForm /> */}
        </div>
      </Elements>
    );
  } else {
    return null;
  }
};

export default Checkout;
