import axiosClient from "../apiClient.js";

export const authenticate = (email, password) => {
  return axiosClient.post("/auth/sign_in", { email, password });
};

export const getUser = () => {
  return axiosClient.get("/user", { headers: { some: "some" } });
};

export const forgotPassword = (email) => {
  const url = new URL(window.location.href);
  const origin = url.origin;
  return axiosClient.post("/auth/password", {
    email,
    redirect_url: `${origin}/reset-password`,
  });
};

export const resetPassword = (password, confirmPassword) => {
  return axiosClient.put(`/auth/password`, {
    password,
    password_confirmation: confirmPassword,
  });
};

export const logout = () => {
  return axiosClient.delete("/auth/sign_out");
};
