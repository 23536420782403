import axiosClient from "../apiClient.js";

export const getOrderedEsimsOfUser = () => {
  return axiosClient.get("/order");
};

export const getAvailableBundles = (country) => {
  return axiosClient.get(`/bundles?country=${country}`);
};

export const getStripeClientSecret = (bundleId, iccid) => {
  return axiosClient.post("/payments", {
    payment: { bundle_id: bundleId, iccid: iccid },
  });
};
