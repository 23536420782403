import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getOrderedEsimsOfUser } from "../core/network/lib/orderContext.js";
import { logout as logoutRequest } from "../core/network/lib/userContext.js";
import { useAuth } from "../core/context/authContext.jsx";
import { toast } from "react-toastify";
import ProgressBar from "../components/ProgressBar.jsx";
import LoadingSpinner from "../components/LoadingSpinner.jsx";
import { extractErrorMessage } from "../core/utils/extractErrorMessage.js";

const Home = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(true);

  const handleLogout = async () => {
    try {
      await logoutRequest();
      logout();
    } catch (e) {
      toast.error(extractErrorMessage(e));
      console.log(e);
    }
  };

  const handleEsimClick = (order) => {
    navigate("/bundles", { state: { order } });
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getOrderedEsimsOfUser();
        setOrders(result?.data);
      } catch (e) {
        toast.error(extractErrorMessage(e));
        console.log(e);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntentParam = urlParams.get("payment_intent");
    if (paymentIntentParam) {
      toast.success("Payment initiated");
      navigate("/");
    }
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <button
              onClick={handleLogout}
              className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-8"
            >
              Logout
            </button>
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                These are Your ESims
              </h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Choose For more options
              </p>
            </div>
            <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {orders.map((order) => (
                <button
                  key={order?.id}
                  className="flex hover:bg-slate-100 bg-slate-50 max-w-xl flex-col items-start justify-between border p-2 rounded-lg"
                  onClick={() => handleEsimClick(order)}
                >
                  <div className="flex items-center gap-x-4 text-xs">
                    <time
                      dateTime={order?.created_at}
                      className="text-gray-500"
                    >
                      {localDate(order?.created_at)}{" "}
                    </time>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      {order?.description}
                    </h3>
                  </div>
                  <ProgressBar
                    bgcolor="#00695c"
                    completed={Math.round(
                      (100 / order.initialQuantity) * order.remainingQuantity
                    )}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const localDate = (time) => {
  const date = new Date(time);
  const localTime = date.toLocaleString();
  return localTime;
};

export default Home;
