import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "../core/network/lib/userContext.js";
import { extractErrorMessage } from "../core/utils/extractErrorMessage.js";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const validatePasswordMatchAndLength = () => {
    if (password.length < 6) {
      toast.error("Passwords need to be greater then 6 characters");
      return false;
    }

    if (password === confirmPassword) {
      return true;
    } else {
      toast.error(
        "Passwords do not match. Please make sure your passwords match before proceeding."
      );
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHeadersInLocalStorage();
    if (!validatePasswordMatchAndLength()) return;

    try {
      await resetPassword(password, confirmPassword);
      toast.success("success");
      navigate("/login");
    } catch (e) {
      toast.error(extractErrorMessage(e));
    }
  };

  const setHeadersInLocalStorage = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const urlHeaderParams = {
      "access-token": searchParams.get("access-token"),
      client: searchParams.get("client"),
      client_id: searchParams.get("client_id"),
      config: searchParams.get("config"),
      expiry: searchParams.get("expiry"),
      token: searchParams.get("token"),
      uid: searchParams.get("uid"),
    };

    localStorage.setItem("accessToken", urlHeaderParams["access-token"]);
    localStorage.setItem("client", urlHeaderParams.client);
    localStorage.setItem("expiry", urlHeaderParams.expiry);
    localStorage.setItem("token", urlHeaderParams.token);
    localStorage.setItem("uid", urlHeaderParams.uid);
  };

  return (
    <html class="h-full bg-white">
      <body class="h-full">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-10 w-auto"
              src="/GoSimLogo.webp"
              alt="GoSim"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </body>
    </html>
  );
};

export default ResetPassword;
