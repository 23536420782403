import axios from "axios";
import { config } from "../utils/config.js";

const axiosClient = axios.create({
  baseURL: config().backend_url,
  headers: {
    "Content-Type": "application/json",
  },
});

const checkResponseStatus = (response) => {
  if (response == undefined) {
    return Promise.reject(response);
  }
};

axiosClient.interceptors.response.use(
  (response) => {
    if (response.headers.uid) localStorage.setItem("uid", response.headers.uid);
    if (response.headers["access-token"])
      localStorage.setItem("accessToken", response.headers["access-token"]);
    if (response.headers.client)
      localStorage.setItem("client", response.headers.client);
    if (response.headers.expiry)
      localStorage.setItem("expiry", response.headers.expiry);
    if (response.headers["token-type"])
      localStorage.setItem("tokenType", response.headers["token-type"]);

    checkResponseStatus(response);

    return response;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use((request) => {
  request.headers.uid = localStorage.getItem("uid") || "";
  request.headers["access-token"] = localStorage.getItem("accessToken") || "";
  request.headers.client = localStorage.getItem("client") || "";
  request.headers.expiry = localStorage.getItem("expiry") || "";
  request.headers.tokenType = localStorage.getItem("tokenType") || "Bearer";

  return request;
});

export default axiosClient;
